<template>
  <AdminLayouts>
    
  </AdminLayouts>
</template>

<script>
import AdminLayouts from "@/layouts/AdminLayouts";
export default {
  name: "AdminDashboard",
  components: { AdminLayouts },
};
</script>