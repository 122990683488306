<template>
  <div class="card mx-4">
    <div class="card-header text-dark bg-light text-start">Featured</div>
    <div class="card-body">Dashboard</div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>

<style>
</style>