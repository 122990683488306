<template>
  <h1>UserDashboard</h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: "UserDashboard",
};
</script>