<template>
  <footer class="p-2 d-flex justify-content-end bg-secondary text-light">
    <h5>Footer</h5>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>